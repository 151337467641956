@tailwind base;

* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--primary);
}

@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: transparent;
    --secondary: #82603f;
    --border-radius: 12px;
    --swiper-theme-color: #0a3254 !important;
    --swiper-scrollbar-drag-bg-color: #c5a477 !important;
    --swiper-navigation-top-offset: 40%;
    --loading-grey: #ededed;
  }

  @font-face {
    font-family: "Poppins-fallback";
    size-adjust: 112.5%;
    src: local("Arial");
  }

  html,
  body {
    background-color: transparent;
    transition: background-color 1s ease;
    padding: 0;
    margin: 0;
    font-family: var(--font-poppins), "Poppins-fallback";
    line-height: 1.5em;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth !important;
  }
}

#navbar {
  transition: all 0.3s !important;
}

.scroll-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  will-change: transform;
}

.arrow-exp {
  margin-top: -95px;
  margin-bottom: 100px;
  position: relative;
  display: table;
  margin: auto;
  font-weight: 300;
  letter-spacing: 2px;
}

.arrow-exp::before {
  content: "";
  position: absolute;
  background-image: url("/assets/arrow-exp.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow-exp::after {
  content: "";
  position: absolute;
  background-image: url("/assets/arrow-exp.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow-exp::before {
  top: 30px;
  left: 50%;
  margin-left: -8px;
  width: 16px;
  height: 10px;
}

.arrow-exp::after {
  top: 30px;
  left: 50%;
  margin-left: -6px;
  width: 12px;
  height: 7px;
  animation: anima 1.2s linear infinite;
}

@keyframes anima {
  0% {
    top: 35px;
    opacity: 1;
  }
  100% {
    top: 55px;
    opacity: 0;
  }
}

@-webkit-keyframes anima {
  0% {
    top: 35px;
    opacity: 1;
  }
  100% {
    top: 60px;
    opacity: 0;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.swiper-pagination {
  bottom: 20px !important;
}

.swiper-pagination-bullet {
  padding: 1px !important;
  border-width: 2px;
  border-color: white;
  background: white !important;
}

.swiper-button-prev,
.swiper-button-next {
  transition: all 0.3s !important;
  top: 50% !important;
}

@media screen and (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    visibility: hidden !important;
  }
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 30px !important;
  right: auto;
  opacity: 0.75;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: url("/assets/prev-01.webp") !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 30px !important;
  left: auto;
  opacity: 0.75;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  opacity: 1;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: url("/assets/next-01.webp") !important;
}

/*effect-underline*/
.effect-underline:after {
  color: #82603f;
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 2px solid;
  margin-top: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}

.effect-underline2:after {
  color: #82603f;
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 2px solid;
  margin-top: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}

.effect-underline2:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.effect-underline:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.masonry-grid {
  display: flex;
  width: auto;
}

.masonry-grid_column {
  background-clip: padding-box;
}

.gallery-image {
  width: 100%;
  height: auto;
  display: block;
}

.image {
}

.loading .image,
.loading h4,
.loading .description {
  background-color: var(--loading-grey);
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    var(--loading-grey);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

.bussiness_card:hover img {
  -webkit-transform: scale(1.5);
  transform: scale(1.1);
}

.bussiness_card img {
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.circle-loader {
  margin-bottom: 1.5em;
  border: 1px solid #fff;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 7em;
  height: 7em;
}

.load-complete {
  animation: none;
  border-color: #fff;
  transition: border 500ms ease-out;
}

.checkmark {
  display: block;
}

.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}

.checkmark:after {
  opacity: 1;
  height: 3.5em;
  width: 1.75em;
  transform-origin: left top;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  content: "";
  left: 1.8em;
  top: 3.5em;
  position: absolute;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 1.75em;
    opacity: 1;
  }
  40% {
    height: 1.75em;
    width: 1.75em;
    opacity: 1;
  }
  100% {
    height: 3.5em;
    width: 1.75em;
    opacity: 1;
  }
}

#mainCarousel,
#mainCarousel2,
#mainCarousel3 {
  width: 100%;
  margin: 0 auto 0 auto;
  --carousel-button-color: #170724;
  --carousel-button-bg: transparent;
  --carousel-button-width: 64px;
  --carousel-button-height: 64px;
  --carousel-button-svg-stroke-width: 0;
}

#mainCarousel .carousel__slide,
#mainCarousel2 .carousel__slide,
#mainCarousel3 .carousel__slide {
  width: 100%;
  padding: 0;
}

#mainCarousel .carousel__button.is-prev,
#mainCarousel2 .carousel__button.is-prev,
#mainCarousel3 .carousel__button.is-prev {
  left: 2rem;
  content: url("/assets/Component15.svg");
  opacity: 0.5 !important;
}

#mainCarousel .carousel__button.is-next,
#mainCarousel2 .carousel__button.is-next,
#mainCarousel3 .carousel__button.is-next {
  right: 2rem;
  content: url("/assets/Component14.svg");
  opacity: 0.5 !important;
}

@media screen and (max-width: 768px) {
  #mainCarousel .carousel__button.is-prev,
  #mainCarousel2 .carousel__button.is-prev,
  #mainCarousel3 .carousel__button.is-prev {
    visibility: hidden;
  }

  #mainCarousel .carousel__button.is-next,
  #mainCarousel2 .carousel__button.is-next,
  #mainCarousel3 .carousel__button.is-next {
    visibility: hidden;
  }
}

#mainCarousel .carousel__button.is-prev:hover,
#mainCarousel2 .carousel__button.is-prev:hover {
  opacity: 1 !important;
}

#mainCarousel .carousel__button.is-next:hover,
#mainCarousel2 .carousel__button.is-next:hover {
  opacity: 1 !important;
}

#mainCarousel .carousel__button:focus,
#mainCarousel2 .carousel__button:focus,
#mainCarousel3 .carousel__button:focus {
  outline: none;
  box-shadow: 0 0 0 0px #a78bfa;
}

#thumbCarousel .carousel__slide,
#thumbCarousel2 .carousel__slide,
#thumbCarousel3 .carousel__slide {
  opacity: 0.5;
  padding: 0;
  margin: 0.25rem;
  width: 96px;
  height: 64px;
}

#thumbCarousel .carousel__slide img,
#thumbCarousel2 .carousel__slide img,
#thumbCarousel3 .carousel__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px;
}

#thumbCarousel .carousel__slide.is-nav-selected,
#thumbCarousel2 .carousel__slide.is-nav-selected,
#thumbCarousel3 .carousel__slide.is-nav-selected {
  opacity: 1;
}

.loader {
  margin: 0 0 2em;
  height: 100px;
  width: 20%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
}

.button-anima {
  border: none;
  color: #fff;
  background-image: linear-gradient(30deg, #704f38, #82603f);
  background-size: 100% auto;
}

.button-anima:hover {
  background-position: right center;
  background-size: 200% auto;
  -webkit-animation: pulse 2s infinite;
  animation: pulse512 1.5s infinite;
}

@keyframes pulse512 {
  0% {
    box-shadow: 0 0 0 0 #a27f50;
  }

  70% {
    box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
  }

  100% {
    box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
  }
}
